// アプリユーザの管理

<template lang="pug">
el-card

  //- 戻るリンク
  div
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  h2.mt-4.font-bold.text-md.text-teal-700 アプリユーザーの一覧

  //- ユーザ一覧
  el-table.mt-2(:data='applicants')

    el-table-column(
      label='ID',
      prop  ='id'
    )

    el-table-column(
      label='名前'
    )
      template(slot-scope='scope')
        span {{ scope.row.firstName }} {{ scope.row.lastName }}

    el-table-column(
      label='性別'
    )
      template(slot-scope='scope')
        span(v-if='scope.row.attributes.genderCode && scope.row.attributes.genderCode !== "unknown"')
          | {{ scope.row.attributes.genderCode === 'female' ? '女性' : '男性' }}
        span(v-else) 未回答

    el-table-column(
      label='学校・卒業年度'
    )
      template(slot-scope='scope')
        span(v-if='scope.row.attributes.school && scope.row.attributes.graduationFiscalYear')
          |  {{ scope.row.attributes.school }} {{ scope.row.attributes.graduationFiscalYear }}
        span(v-else) 未回答

    el-table-column(
      label='メール',
      prop='email'
    )

    el-table-column(align='right')
      template(slot-scope='scope')
        el-button.btn(
          size='mini',
          type='warning',
          plain,
          @click='changePassword(scope.row.id)'
        ) パスワード変更
</template>

<script>
import userService from '@/services/user'

export default {
  name: 'AdminAppUsers',

  data() {
    return {
      applicants: undefined,
    }
  },

  async created() {
    try {
      const res = await userService.getApplicantsForAdmin()
      if (!res.ok) throw new Error('cannot get appricants.')
      this.applicants = res.payload.items
    } catch (error) {
      console.error(error)
    }
  },

  methods: {
    // 指定ユーザのパスワードを変更
    // TODO: 会員園のパスワード変更時のコードのコピペなので、共通化したい
    async changePassword(userId) {
      let password
      try {
        const result = await this.$prompt(
          '新しいパスワードを入力してください。',
          'パスワードの変更',
          {
            confirmButtonText: '変更',
            cancelButtonText: 'キャンセル',
            inputPattern: /^[!-~]{8,}$/,
            inputErrorMessage: '半角8文字以上で入力してください。',
          }
        )
        password = result.value
      } catch (e) {
        return
      }
      const response = await userService.updateUserPassword(userId, password)
      if (!response.ok) {
        this.$alert('変更できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '変更しました。',
          type: 'success',
        })
      }
    },
  },
}
</script>
